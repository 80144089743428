import React from 'react';

import { Flex, Spinner as NBSpinner } from 'native-base';

const Spinner = () => {
  return (
    <Flex
      p={4}
      bg="neutral.background"
      height="100vh"
      width="100vw"
      display="flex"
      justifyContent="center"
      position="absolute"
      zIndex={1}
    >
      <NBSpinner color="interactive.primary.active.default" size={40} />
    </Flex>
  );
};

export default Spinner;
