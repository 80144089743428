import { Store, configureStore } from '@reduxjs/toolkit';

import { RootActions, RootState, rootReducer } from '../reducers';

export let store: Store<RootState, RootActions>;

export const createStore = (
  preloadedState: Partial<RootState> = {}
): Store<RootState, RootActions> => {
  store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
  return store;
};
