export const CONFIG_UPDATE = 'CONFIG_UPDATE';

interface UpdateConfigAction {
  type: typeof CONFIG_UPDATE;
  config: ConfigState;
}

export type ConfigActions = UpdateConfigAction;

export type ConfigState = Config;
