export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export interface ModalState {
  isOpen: boolean;
  modalProps: ModalProps;
}

export interface ModalProps {
  title?: string;
  body?: JSX.Element;
  footer?: JSX.Element;
  message?: { msgKey: string; count?: number };
  messages?: { msgKey: string; count?: number }[];
  onClose?: () => void;
}

export interface ModalShowAction {
  type: typeof SHOW_MODAL;
  modalProps: ModalProps;
}

export interface ModalCloseAction {
  type: typeof CLOSE_MODAL;
}

export type ModalActions = ModalShowAction | ModalCloseAction;
