export const REGISTER = 'REGISTER';
export const REGISTER_CLEAR = 'REGISTER_CLEAR';
export const MINOR_FLOW = 'MINOR_FLOW';

export type RegisterState = Register;

export interface RegisterAction {
  type: typeof REGISTER;
  register: Register;
}

export interface MinorFlowAction {
  type: typeof MINOR_FLOW;
  isMinorFlow: boolean;
  register?: Register;
}

export interface RegisterClearAction {
  type: typeof REGISTER_CLEAR;
}

export type RegisterActions = RegisterAction | RegisterClearAction | MinorFlowAction;
