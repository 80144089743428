import { AxiosRequestConfig } from 'axios';

import { ApiStatus } from 'Enums';

import { MockApiHandler, MockApiResponse } from './api';
import { handleIncorrectPasswordError } from './auth';
import { makePatient } from './seeds/patient';

const validatePassword = (password: string): MockApiResponse | null => {
  switch (password) {
    case 'librelibrelibre':
      return {
        status: ApiStatus.BAD_REQUEST,
        response: {
          data: {
            code: ApiStatus.PASSWORD_VALIDATION,
            errors: [
              {
                error: 'context',
                key: 'password',
              },
            ],
            details: 'password.context',
          },
        },
      };
    case '1234password':
      return {
        status: ApiStatus.BAD_REQUEST,
        response: {
          data: {
            code: ApiStatus.PASSWORD_VALIDATION,
            errors: [
              {
                error: 'sequential',
                key: 'password',
              },
            ],
            details: 'password.sequential',
          },
        },
      };
    case 'aaabcpassword':
      return {
        status: ApiStatus.BAD_REQUEST,
        response: {
          data: {
            code: ApiStatus.PASSWORD_VALIDATION,
            errors: [
              {
                error: 'repeat',
                key: 'password',
              },
            ],
            details: 'password.repeat',
          },
        },
      };
    case '123qweasdzxc':
      return {
        status: ApiStatus.BAD_REQUEST,
        response: {
          data: {
            code: ApiStatus.PASSWORD_VALIDATION,
            errors: [
              {
                error: 'dictionary',
                key: 'password',
              },
            ],
            details: 'password.dictionary',
          },
        },
      };
    default:
      return null;
  }
};

const handleRegister = (request: AxiosRequestConfig): MockApiResponse => {
  if (!request.data) {
    return {
      status: ApiStatus.BAD_REQUEST,
      message: 'Bad Request',
    };
  }

  const { Email, Password } = request.data;

  if (Email === 'emailexists@abbott.com') {
    return {
      status: ApiStatus.CONFLICT,
      response: {
        data: {
          code: ApiStatus.PATIENT_EXISTS,
          details: 'Patient already exists',
        },
      },
    };
  }

  const res = validatePassword(Password);

  if (res) {
    return res;
  }

  return {
    status: ApiStatus.OK,
  };
};

const handleUpdatePassword = (
  request: AxiosRequestConfig<{ previousPassword: string; password: string }>
): MockApiResponse => {
  if (!request.data) {
    return {
      status: ApiStatus.BAD_REQUEST,
      message: 'Bad Request',
    };
  }

  const currentPassword = window.atob(request.headers?.BasicAuth).split(':')[1];

  if (currentPassword === 'incorrect') {
    return handleIncorrectPasswordError();
  }

  const { password } = request.data;

  if (password === 'sameoldpassword') {
    return {
      status: ApiStatus.BAD_REQUEST,
      response: {
        data: {
          code: ApiStatus.PASSWORD_VALIDATION,
        },
      },
    };
  }

  const res = validatePassword(password);

  if (res) {
    return res;
  }

  return {
    status: ApiStatus.OK,
  };
};

const handleGetPatient = (): MockApiResponse => {
  return {
    status: ApiStatus.OK,
    data: makePatient(),
  };
};

const handleUpdatePatient = (): MockApiResponse => {
  return {
    status: ApiStatus.OK,
  };
};

export const patientHandlers: MockApiHandler[] = [
  {
    match: /patient$/,
    handle: handleRegister,
    method: 'POST',
    name: 'patient',
  },
  {
    match: /patient/,
    handle: handleGetPatient,
    method: 'GET',
    name: 'patient',
  },
  {
    match: /patient/,
    handle: handleUpdatePatient,
    method: 'PUT',
    name: 'patient',
  },
  {
    match: /password$/,
    handle: handleUpdatePassword,
    method: 'PATCH',
    name: 'patient',
  },
];
