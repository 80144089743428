import { CONFIG_UPDATE } from 'Reducers/config/types';

import { generateUserAgentHeader, urls } from 'Utilities/dataHelpers';
import * as log from 'Utilities/log';
import mediator from 'Utilities/mediator';
import { store } from 'Utilities/store';

export const getConfig = (
  compatibility: boolean,
  country: string,
  app?: string,
  appVersion?: string,
  deviceManufacturer?: string,
  deviceModel?: string,
  osType?: string,
  osVersion?: string
) => {
  return new Promise<Config>((resolve, reject) => {
    mediator
      .request<ApiResponse<Config>>('api:invoke', {
        method: 'GET',
        url: urls.libreApiUrl + 'config',
        headers: {
          'X-User-Agent': generateUserAgentHeader(app, appVersion, osType, osVersion),
        },
        params: {
          country,
          compatibility,
          deviceManufacturer,
          deviceModel,
        },
      })
      .then((result) => {
        if (result.data) {
          return resolve(result.data);
        }

        reject(result);
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};

export const updateConfig = (
  compatibility: boolean,
  country = 'US',
  app?: string,
  appVersion?: string,
  deviceManufacturer = 'none',
  deviceModel = 'none',
  osType?: string,
  osVersion?: string
) => {
  return new Promise<void>((resolve, reject) => {
    getConfig(
      compatibility,
      country,
      app,
      appVersion,
      deviceManufacturer,
      deviceModel,
      osType,
      osVersion
    )
      .then((config) => {
        store.dispatch({ type: CONFIG_UPDATE, config });
        resolve();
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};
