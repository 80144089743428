import faker from 'faker';

export const makePatient = (): Partial<Patient> => {
  const newPatient: Patient = {
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    email: faker.internet.email(),
    country: 'US',
    language: 'en-US',
    dateOfBirth: faker.date.past().toISOString(),
    guardianFirstName: faker.name.firstName(),
    guardianLastName: faker.name.lastName(),
    consents: {
      hipaa: {
        accepted: false,
        at: new Date().getTime(),
      },
      pp: {
        accepted: true,
        at: new Date().getTime(),
      },
      touLibre: {
        accepted: true,
        at: new Date().getTime(),
      },
    },
  };

  return newPatient;
};
