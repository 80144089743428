import { CLOSE_MODAL, ModalActions, ModalState, SHOW_MODAL } from './types';

const initialState: ModalState = {
  isOpen: false,
  modalProps: {},
};

export const modalReducer = (state = initialState, action: ModalActions): ModalState => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        isOpen: true,
        modalProps: action.modalProps,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
};
