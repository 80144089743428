import { Method } from 'axios';

import * as log from 'Utilities/log';
import { MediatorRequest } from 'Utilities/mediator';

import { authHandlers } from './auth';
import { configHandlers } from './config';
import { consentHandlers } from './consent';
import { patientHandlers } from './patient';

export interface MockApiResponse<T = any> {
  status: number;
  data?: T;
  message?: string;
  response?: {
    headers?: { [key: string]: string | number };
    data: T;
  };
}

export interface MockApiHandler {
  handle: (request: MediatorRequest) => MockApiResponse;
  match: RegExp;
  method: Method;
  name: string;
}

/**
 * This is the api service that will handle MOCKing requests
 * Current pattern: you need to make available in your mock services an exported
 * array of objects that match the handler format below
 * Then you need to import each one you want loaded and spread apply them to
 * the local _handlers array
 */

/**
 * The structure of a handler is:
 * {
 *  "handle": func(),
 *  "match": /regexpr/,
 *  "method": string : GET,POST,PUT,DELETE
 *  "name": "not needed"
 * }
 */
const _handlers: MockApiHandler[] = [
  ...configHandlers,
  ...authHandlers,
  ...patientHandlers,
  ...consentHandlers,
];

const maxTime = 500;
const minTime = 100;
const waitTime = () => Math.floor(Math.random() * (maxTime - minTime) + minTime);

export const mockInvoke = (request: MediatorRequest) => {
  // Remove the MOCK from the url so that the matching doesn't need to worry about it
  request.url = request.url.replace('MOCK', '');

  for (const handler of _handlers) {
    if (
      request.url.match(handler.match) &&
      (!handler.method || (handler.method && request.method === handler.method))
    ) {
      request.response = new Promise((resolve, reject) => {
        try {
          const res = handler.handle(request);

          if (res) {
            log.groupAndTrace(
              `MOCK-API: result for (${handler.name}) on ${request.url}`,
              ['request', request],
              ['result', res]
            );
          }

          window.setTimeout(() => {
            if ([200, 201].includes(res.status)) {
              return resolve(res);
            }

            reject(res);
          }, waitTime());
        } catch (e) {
          log.error(
            `MOCK-API: the handler (${handler.name}) that matched on ${request.url} unexpectedly failed`,
            request
          );
          reject(e);
        }
      });

      break;
    }
  }

  if (!request.response) {
    request.response = new Promise((_, reject) => {
      log.debug(`MOCK-API: no matched handler for url (${request.url})`);
      reject('no handler for that API call');
    });
  }
};
