export interface EnvConfig {
  adultAges: {
    [key: string]: number;
  };
  countries: string[];
  languages: string[];
  libreApiUrl: string;
  supportedCountries: string[];
  environment: string;
  logLevel: string;
  mock: boolean;
  production: boolean;
}

export const _config = {} as EnvConfig;

export const getEnvConfig = (): Promise<EnvConfig> => {
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
    },
  };

  return new Promise((resolve, reject) => {
    fetch('/config.json', options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

        throw new Error('status was not OK');
      })
      .then((data) => {
        Object.assign(_config, data);
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
