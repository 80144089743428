import { useSelector } from 'react-redux';

import i18n from 'Utilities/i18n';

function useGetAsset() {
  const message = useSelector<{ env: { theme: string } }>((state) => state.env.theme);
  const isDarkMode = message === 'polarisDark' || message === 'otcDark';

  /**
   * This function returns an asset path based on whether the current color scheme is
   * dark or not.
   * @param {string} assetString - The `assetString` parameter is a string that
   * represents the name or path of an asset, such as an image or icon. The function
   * `getAssetInColorScheme` takes this parameter as input and returns the path of
   * the asset based on the current color scheme (light or dark mode).
   * @returns The function `getAssetInColorScheme` returns a string which is the
   * asset path. The asset path is determined based on whether the current mode is
   * dark mode or not. If the asset with the name
   * assetStringDark or assetStringLight exists in the i18n translation file, then the
   * function returns the value of that asset. Otherwise, it returns the default value from the assetString.
   */
  const getAssetInColorScheme = (assetString: string): string => {
    const themedColor = isDarkMode ? `${assetString.trim()}Dark` : `${assetString.trim()}Light`;

    return i18n.exists(themedColor) ? i18n.t(themedColor) : i18n.t(assetString);
  };

  return { getAssetInColorScheme };
}

export default useGetAsset;
