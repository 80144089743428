import { ApiStatus } from 'Enums';

import { MockApiHandler, MockApiResponse } from './api';
import * as configSeeds from './seeds/config';

const handleConfig = (): MockApiResponse => {
  return {
    status: ApiStatus.OK,
    data: configSeeds.getConfig(),
  };
};

export const configHandlers: MockApiHandler[] = [
  {
    match: /config$/,
    handle: handleConfig,
    method: 'GET',
    name: 'config/base',
  },
];
