// import { _config } from 'Utility/envConfig';
const _config = { mock: true };

if (!String.prototype.format) {
  String.prototype.format = function (...args: any[]) {
    let str = this.toString();

    if (!args.length) {
      return str;
    }

    const values = typeof args[0] === 'object' ? args[0] : args[1];

    for (let i = 0; i < args.length; i++) {
      const arg = values[i];

      str = str.replace(new RegExp('\\{' + i + '\\}', 'gi'), arg);
    }

    return str;
  };
}

// proper global regex function
if (!String.prototype.matchGlobal) {
  String.prototype.matchGlobal = function (reg: RegExp) {
    const str = this.toString();

    if (!reg) {
      return null;
    }

    const result = [] as string[][];
    let current: RegExpExecArray | null;

    while ((current = reg.exec(str))) {
      result.push([...current]);
    }

    return result;
  };
}

export const _handleMockMessage = (message: string) => {
  return 'MOCK:' + message;
};

/**
 * Prepends `MOCK:` to the front of any mediator message if we are in the MOCKed world
 * - message: string - Any mediator message
 */
export const _handleMediatorMessage = (message: string) => {
  if (_config.mock) {
    return _handleMockMessage(message);
  }

  return message;
};

export const actionKeyPressed = (e: KeyboardEvent) => {
  const key = e.keyCode ? e.keyCode : e.which;

  return key === 13;
};

/**
 * This will add the country and uiLanguage to the link as query string parameters. The primary
 * use case is for any support site link
 *
 * @param {string} url the url (tends to be support links)
 * @param {string} country the user's country code (added to the link)
 * @param {string} lang the user's uiLanguage (added to the link)
 */
export const addCountryLangToUrl = (url: string, country: string, lang: string) => {
  if (!country && !lang) {
    return url;
  }

  let addQuery = '&';

  if (url.indexOf('?') === -1) {
    addQuery = '?';
  }

  const query =
    (lang ? `lang=${lang}` : '') +
    (lang && country ? '&' : '') +
    (country ? `country=${country}` : '');

  return url + (query ? addQuery + query : '');
};

const detectGPU = () => {
  const isCanvasSupported = function () {
    const elem = document.createElement('canvas');

    return !!(elem.getContext && elem.getContext('2d'));
  };

  const loseWebglContext = function (context: any) {
    const loseContextExtension = context.getExtension('WEBGL_lose_context');

    if (loseContextExtension != null) {
      loseContextExtension.loseContext();
    }
  };

  const getWebglCanvas = function () {
    const canvas = document.createElement('canvas');
    let gl = null;

    try {
      gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    } catch (e) {}

    if (!gl) {
      gl = null;
    }

    return gl;
  };

  // code taken from Modernizr
  if (!isCanvasSupported()) {
    return '';
  }

  const glContext = getWebglCanvas();
  let renderer = '';

  if (!!window.WebGLRenderingContext && !!glContext) {
    try {
      const debugInfo = (glContext as any).getExtension('WEBGL_debug_renderer_info');

      if (debugInfo) {
        renderer = (glContext as any).getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
      }

      loseWebglContext(glContext);
    } catch (e) {}
  }

  return renderer;
};

/**
 * This method will generate a fairly unique 'fingerprint' of the current browser.
 * It only needs to be unique among the devices that the user logs in with.
 */
export const browserFingerprint = () => {
  const winNav = window.navigator;
  let guid = winNav.mimeTypes.length + ' ';

  guid += navigator.appVersion.replace(/[0-9]/g, '').replace(/\s/g, '') + ' ';
  guid += winNav.userAgent.replace(/[0-9]/g, '').replace(/\s/g, '') + ' ';
  guid += detectGPU().replace(/\s/g, '') + ' ';
  guid += new Date().getTimezoneOffset() + ' ';
  guid += winNav.plugins.length + ' ';
  guid += navigator.platform + ' ';

  if (winNav.hardwareConcurrency) {
    guid += winNav.hardwareConcurrency + ' ';
  }

  if ((navigator as any).deviceMemory) {
    guid += (navigator as any).deviceMemory + ' ';
  }

  return guid;
};

/**
 * This method will take a function and return to you one that will be 'debounced'.
 * Essentially what that means is the method will not be executed until after `time` has passed.
 * If the function is called back-to-back within that period of time, the timer is reset on the
 * second call.
 * Additionally, the function will be called with the most recent arguments when the timer finally
 * expires. So if you call the method 3 times within the timer with 3 different arguments, only
 * the third set of arguments will be executed by the method.
 *
 * This is very useful in places where a function can be triggered many times, like detecting
 * scroll events or a user typing in an input.
 *
 * @param {func} func function that you want to debounce
 * @param {Number} time time in miliseconds you want your debounce to wait
 */
export const debounce = (func: (...args: any[]) => void, time = 300) => {
  let timeout: number;

  return (...args: any[]) => {
    const later = () => {
      timeout = 0;
      func(...args);
    };

    window.clearTimeout(timeout);

    timeout = window.setTimeout(later, time);
  };
};

/**
 * This will create a nice formatted code that is displayed to the user. You are expected to pass
 * in one of the constants exported by errorTypes.js and the status code returned by the service call
 * @param {string} codeType Needs to be one of the exports from errorTypes.js
 * @param {string|Number} code Likely the status code from the service call that failed
 */
export const errorCode = (codeType: string, code: string | number) => {
  return `${codeType} [${code}]`;
};

/**
 * Looks at the `dict` and attempts to find `lang` as one of it's keys. Will try to use the
 * 2-code version of `lang` (supposing `lang` is 5-code and the `dict` may have 2-code keys).
 * If that fails to find anything, it will finally use the 'default' key which must be one of
 * the keys on `dict`
 *
 * @param {*} dict Object where the keys are language codes
 * @param {string} lang language code you are trying to find in the `dict`
 */
export const getFromLang = (
  dict: { default: string; [key: string]: string | undefined },
  lang?: string
) => {
  if (lang) {
    return dict[lang] || dict[lang.split('-')[0]] || dict['default'];
  }

  return dict['default'];
};

/**
 * This method will return true of the object is empty (or if it's null/undefined)
 *
 * @param {Object} obj the object you want to check if it is empty
 */
export const isObjectEmpty = (obj: any) => {
  if (!obj) {
    return true;
  }

  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

const _typeCache = {} as any;

/**
 * Efficiently check what type a value is and accurately describe what kind of object it is
 *
 * @param {*} obj Value to check
 */
export const type = (obj: any) => {
  let key;

  return obj === null
    ? 'null'
    : obj === global
    ? 'global'
    : (key = typeof obj) !== 'object' // Simple values like string, number, boolean, etc
    ? key
    : obj.nodeType // DOM elements
    ? 'object'
    : _typeCache[(key = Object.prototype.toString.call(obj))] || // object type like array, date, regexp, etc
      (_typeCache[key] = key.slice(8, -1).toLowerCase());
};

export const maskAllButLast4Digits = (phoneNumber: string) => {
  if (!phoneNumber || phoneNumber.length <= 4) {
    return phoneNumber;
  }

  let index = phoneNumber.length - 1,
    nonMaskDigits = 1;

  for (; index >= 0 && nonMaskDigits < 4; index--) {
    phoneNumber[index] >= '0' && phoneNumber[index] <= '9' ? nonMaskDigits++ : null;
  }

  return phoneNumber.substr(0, index).replace(/\d/g, '*') + phoneNumber.substr(index);
};

/**
 * This is a helper method to set up a listener to the redux store. You pass in the reference
 * to the store as well as a selector function which is used to get the item of the store you care about.
 * The observer will then pay attention to this item and see when it's been changed (by reference for
 * objects and by value for primitives).
 * When there is a change, it will execute the callback and pass it the updated value.
 *
 * You can use this to easily watch for changes in the store outside of react components (which should
 * never need this method since the components can connect to the store via react-redux's connect)
 *
 * @param {*} store This is a reference to the redux store
 * @param {func} select Selector function
 * @param {func} onChange Callback to execute when there is a change
 */
export function observeStore(store: any, select: (arg: any) => any, onChange: (arg: any) => void) {
  let currentState: any;

  function handleChange() {
    const nextState = select(store.getState());

    if (nextState !== currentState) {
      currentState = nextState;
      onChange(currentState);
    }
  }

  const unsubscribe = store.subscribe(handleChange);

  handleChange();
  return unsubscribe;
}

/**
 * Use to see how many of an item is in the array
 * @param {[]} arr array to check
 * @param {*} item item to look for
 */
export const occuranceArray = (arr: any[], item: any) => {
  return arr.reduce((cv, nv) => (nv === item ? [...cv, true] : cv), []).length;
};

/**
 * Takes an array of objects that are expected to have an 'id' property and returns an object that has
 * each item indexable by the 'id' value
 * @param {array} arr
 */
export const reduceToObjectById = (arr: any[]) => {
  if (!arr || !Array.isArray(arr)) {
    throw 'must pass in an array';
  }

  return arr.reduce((pv, c) => (c.id && !pv[c.id] ? { ...pv, [c.id]: c } : pv), {});
};

/**
 * This is a way to check if an element has been scrolled to the bottom.
 * Calling this method returns a function which keeps context of the previous scroll position
 * This function will in turn return a promise that resolves to true/false if the bottom
 * was reached.
 *
 * Look at MainContentWrapper for the best example usage
 */
export const scrolledToBottomAsync = (time = 0) => {
  let prevScroll = 0;

  const func = (elem: Element, range = 10, resolve: (value?: boolean) => void) => {
    if (!elem) {
      return resolve(false);
    }

    if (elem.scrollTop <= prevScroll) {
      prevScroll = elem.scrollTop;
      return resolve(false);
    }

    prevScroll = elem.scrollTop;
    const diff = elem.scrollHeight - elem.scrollTop;

    if (diff >= elem.clientHeight - range && diff <= elem.clientHeight + range) {
      return resolve(true);
    }
  };

  const debouncedCheck = debounce(func, time);

  return (elem: Element, range?: number) =>
    new Promise<boolean>((resolve) => debouncedCheck(elem, range, resolve));
};

export const scrubFilename = (fname: string) => {
  if (!fname) {
    return fname;
  }

  let len = fname.length;

  if (len < 1) {
    return fname;
  }

  const winReserved = [
    'CON',
    'PRN',
    'AUX',
    'NUL',
    'COM1',
    'COM2',
    'COM3',
    'COM4',
    'COM5',
    'COM6',
    'COM7',
    'COM8',
    'COM9',
    'LPT1',
    'LPT2',
    'LPT3',
    'LPT4',
    'LPT5',
    'LPT6',
    'LPT7',
    'LPT8',
    'LPT9',
  ];

  // if fname is exactly windows reserved word just modify (it can contain the word)
  if (winReserved.includes(fname)) {
    return fname + '_';
  }

  // can't have one of  /\:*?"<>|  for win∪mac
  // or char at beginning/end . or just ends with ' '(space)
  const invalidChars = /["*:<>?/|\\]/;
  let scrubbed = fname;

  if (invalidChars.test(fname)) {
    scrubbed = fname.replace(invalidChars, '_');
  }

  const lastChar = scrubbed.charAt(len - 1);

  if (lastChar === ' ' || lastChar === '.') {
    scrubbed = scrubbed.substring(0, len - 1) + '_';
  }

  len = scrubbed.length;

  if (scrubbed.charAt(0) === '.') {
    return len > 1 ? '_' + scrubbed.substring(1, len) : '_';
  }

  return scrubbed;
};

export const shallowCompare = (obj1: any, obj2: any) => {
  if (obj1 === obj2) {
    return true;
  }

  if (obj1 === null || obj1 === undefined || obj2 === null || obj2 === undefined) {
    return false;
  }

  const obj1keys = Object.keys(obj1);
  const obj2keys = Object.keys(obj2);

  if (obj1keys.length !== obj2keys.length) {
    return false;
  }

  const distinctKeys = [...new Set([...obj1keys, ...obj2keys])];

  if (distinctKeys.length !== obj1keys.length) {
    return false;
  }

  for (const key of distinctKeys) {
    if (!obj1.hasOwnProperty(key) || !obj2.hasOwnProperty(key)) {
      return false;
    }

    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

/**
 * This will return a sort method that uses the passed in function to get the
 * value to use from the two items
 * @param {func} fn accessor function
 */
export const sortByProperty = (fn: (value?: unknown) => any) => (a: any, b: any) => {
  const aValue = fn(a);
  const bValue = fn(b);

  return bValue - aValue;
};

export const stackExtractor = (fake: any) => {
  let who = '[?]';

  if (fake.stack) {
    const stack = fake.stack.split('\n');

    try {
      let myWho = '[';
      const myAtLine = stack[2].split('at ')[1];
      const myParts = myAtLine.split(' ');
      let fileInfo = myParts[0];

      if (myParts.length === 2) {
        myWho += myParts[0] + ' ';
        fileInfo = myParts[1];
      }

      // just get the last 2 paths
      const fileParts = fileInfo.split('/');
      const file = fileParts[fileParts.length - 2] + '/' + fileParts[fileParts.length - 1];

      myWho += file.split(')')[0] + ']';
      who = myWho;
    } catch (e) {
      /* nothing */
    }
  }

  return who;
};

/**
 * This will throttle a function call. it will immediately call the function. Then will only call it again
 * after `threshold` time is passed. Note, if it is called twice immediately, the second invocation will
 * happen after the timeout.
 * @param {func} fn functio to call
 * @param {int} threshold the amount of time to wait
 */
export const throttle = (fn: () => void, threshold = 200) => {
  let last = 0;
  let timer: NodeJS.Timeout;

  return () => {
    const now = +new Date();
    const timePassed = !!last && now < last + threshold;

    if (timePassed) {
      clearTimeout(timer);

      timer = setTimeout(() => {
        last = now;
        fn();
      }, threshold);
    } else {
      last = now;
      fn();
    }
  };
};

export const guidRegExp = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';

/** A Relatively simple uuid generator. **NOTE** DO NOT USE FOR SERVER STORED VALUES */
export const uuidv4 = () => {
  return ('' + 1e7 + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: any) =>
    (c ^ (window.crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

/** Wraps the passed in text with bidi direction control characters. Makes isolated LRT text when in RTL mode. */
export const wrapTextAsLtr = (text: string) =>
  document.documentElement.dir === 'rtl' ? `\u202A${text}\u202C\u200F` : text;

/** Escape HTML charactors to prevent xss and injection */
export const htmlEscape = (text: string) => {
  return String(text)
    .replace(/&/g, '&amp;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
};

/**
 * Used to check if a scrollable content area is scrolled all the way to the top or bottom.
 * Returns an object containing top and bottom which are true if the scroll is touching the specific bound.
 * Returns true for top and bottom if no scroll is present or if no element is present.
 *
 * @param elem - DOM element
 *
 * @returns Object with attributes top and bottom which reflect the current scroll state.
 */
export const scrolledTopOrBottom = (elem: Element): ScrolledTopOrBottom => {
  if (!elem) {
    return {
      top: true,
      bottom: true,
    };
  }

  const boundsTouched: ScrolledTopOrBottom = {
    top: false,
    bottom: false,
  };

  const range = 10;
  const diff = elem.scrollHeight - elem.scrollTop;

  if (elem.scrollTop <= range) {
    boundsTouched.top = true;
  }

  if (diff <= elem.clientHeight + range) {
    boundsTouched.bottom = true;
  }

  return boundsTouched;
};
