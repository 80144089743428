export const getDifferenceInMinutesFromNow = (endDate: number) => {
  const now = new Date().getTime();

  endDate = new Date(endDate * 1000).getTime();
  const difference = endDate - now;

  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((difference % (1000 * 60)) / 1000);

  const time = `${minutes}:${('0' + seconds).slice(-2)}`;

  return { time, difference };
};
