/* eslint-disable @typescript-eslint/no-empty-function */
import { stackExtractor } from 'Utilities/newyu';

const _console = window.console;
const _leadingText = {
  DEBUG: 'DEBUG:',
  ERROR: 'ERROR:',
  INFO: 'INFO:',
  PANIC: 'PANIC!!:',
  WARN: 'WARN:',
};

export let level = 'DEBUG';
let _log = () => {};
let _debug = () => {};
let _groupAndTrace = () => {};
let _info = () => {};
let _warn = () => {};
let _error = () => {};

// We execute our logger functions from within a try/catch as IE throws execeptions
// when trying to access console's native functions from other references
const tryLog = function (_logger: (...message: any[]) => void, ...msg: string[]) {
  try {
    // The following check looks to see if we are printing one of our `_leadingText` options, followed by a string
    // If we are, then we want to combine those two strings into one parameter so that text substition still works as intended
    // This is because if the first parameter contains substition characters, then the following parameters will be injected in
    // Per the web standard: console.log(msg [, subst1, ..., substN]);
    if (
      msg.length > 2 &&
      typeof msg[0] === 'string' &&
      typeof msg[1] === 'string' &&
      Object.values(_leadingText).indexOf(msg[0]) !== -1
    ) {
      const [msg1, msg2, ...rest] = msg;

      _logger(`${msg1} ${msg2}`, ...rest);
    } else {
      _logger(...msg);
    }
  } catch {}
};

export const log = (...msg: string[]) => tryLog(_log, ...msg);
export const debug = (...msg: string[]) => tryLog(_debug, _leadingText.DEBUG, ...msg);
/**
 * This will minimally print out a message that is a grouped message.
 * The first argument you pass in is going to be uses at the top level of the group.
 * That message will always be seen and is bold.
 * All additional arguments will be individually printed (via debug log) inside of the group structure.
 * If an additional argument is an array, it will be spread into the debug log.
 * For example, if an argument is `['result', resultObject]` that will be spread as two individual
 * arguments to the debug log method.
 * The final message within the group is a trace which will print out the trace (this works in dev
 * mode despite the webpacking)
 * The group is collapsed initially to keep the console log window a bit cleaner.
 * @param {...any} msg arguments for the log statement
 */
export const groupAndTrace = (...msg: any[]) => tryLog(_groupAndTrace, ...msg);
export const info = (...msg: any[]) => tryLog(_info, _leadingText.INFO, ...msg);
export const warn = (...msg: any[]) => tryLog(_warn, _leadingText.WARN, ...msg);
export const error = (...msg: any[]) => tryLog(_error, _leadingText.ERROR, ...msg);
export const panic = function (...msg: any[]) {
  if (_console && level !== 'OFF') {
    tryLog(_error, _leadingText.PANIC, ...msg, stackExtractor(new Error(...msg)));
    throw new Error(...msg);
  }
};

export const init = (_level: string) => {
  level = _level || level;

  if (_console) {
    if (level !== 'OFF') {
      /* eslint no-fallthrough: "off" */ // Fallthough for logging levels
      switch (level) {
        case 'DEBUG':
          _debug = _console.log;
          _log = _console.log;
          _groupAndTrace = (...msg: any[]) => {
            const title = (msg && msg[0]) || undefined;

            console.groupCollapsed('DEBUG-TRACE: ' + title);

            if (title) {
              msg.slice(1, msg.length).forEach((m) => {
                if (Array.isArray(m)) {
                  log(...m);
                } else {
                  log(m);
                }
              });
            }

            console.trace('trace');
            console.groupEnd();
          };

          console.debug = debug;
          console.log = log;
        case 'INFO':
          _info = _console.info;
          console.info = info;
        case 'WARN':
          _warn = _console.warn;
          console.warn = warn;
        case 'ERROR':
          _error = _console.error;
          console.error = error;
      }
    }

    log('Logger initialized to:' + level);
  }
};

/**
 * Returns the current log level
 */
export const getLogLevel = () => level;

const properitesFilter = ['password'];

const replacer = (key: string, val: any) => {
  if (properitesFilter.includes(key)) {
    return '';
  }

  return val;
};

/**
 * Will return a string version of the object with sensitive properties removed
 * If the object is circular then it will just return null
 * @param {*} obj
 */
export const cleanObjectPropertiesToString = (obj: any): string | null => {
  try {
    return JSON.stringify(obj, replacer, 2);
  } catch {
    return null;
  }
};

/**
 * Will return a new object with sensitive properties removed
 * If the object is circular, it won't clean it and will just return the original
 * object
 * @param {*} obj
 */
export const cleanObjectProperties = (obj: any) => {
  const stringed = cleanObjectPropertiesToString(obj);

  if (stringed === null) {
    return obj;
  }

  return JSON.parse(stringed);
};

export default {
  debug,
  error,
  getLogLevel,
  groupAndTrace,
  info,
  log,
  panic,
  warn,
};
