import React from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Modal } from '@adc-polaris-component-library/component-library';
import { Stack } from 'native-base';

import { CLOSE_MODAL } from 'Reducers/modal/types';

import i18n from 'Utilities/i18n';

import { RootState } from 'src/reducers';

const mapStateToProps = ({ modal }: RootState) => {
  return {
    modal,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

const GeneralModal = ({ modal: { isOpen, modalProps }, dispatch }: Props) => {
  const handleCloseModal = () => {
    dispatch({
      type: CLOSE_MODAL,
    });

    modalProps.onClose && modalProps.onClose();
  };

  return (
    <Modal isOpen={isOpen}>
      {modalProps.title && (
        <Modal.Header testID={modalProps.title}>{i18n.t<string>(modalProps.title)}</Modal.Header>
      )}
      <Modal.Body>
        {modalProps.body ? (
          modalProps.body
        ) : (
          <>
            <Modal.BodyText
              testID={modalProps.message?.msgKey}
              nativeID={modalProps.message?.msgKey}
            >
              {modalProps.message
                ? i18n.t<string>(modalProps.message.msgKey, {
                    count: modalProps.message.count,
                  })
                : ''}
            </Modal.BodyText>
            <Stack space={4}>
              {modalProps.messages?.map((message, i) => {
                return (
                  <Modal.BodyText key={i} testID={message.msgKey} nativeID={message.msgKey}>
                    {i18n.t<string>(message.msgKey, { count: message.count })}
                  </Modal.BodyText>
                );
              })}
            </Stack>
          </>
        )}
      </Modal.Body>
      <Modal.ButtonContainer>
        {modalProps.footer ? (
          modalProps.footer
        ) : (
          <Modal.Button testID="Global.microcopy.common.okCap" onPress={handleCloseModal}>
            {i18n.t<string>('Global.microcopy.common.okCap')}
          </Modal.Button>
        )}
      </Modal.ButtonContainer>
    </Modal>
  );
};

export default connector(GeneralModal);
