import React, { useEffect, useRef } from 'react';

import { Flex, IFlexProps } from 'native-base';

import { MAIN_SIZE } from 'Reducers/scrollPage/types';

import { store } from 'Utilities/store';

const Main = ({ ...rest }: IFlexProps) => {
  const mainRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (store && mainRef.current) {
      store.dispatch({ type: MAIN_SIZE, mainSize: mainRef.current.clientHeight });
    }
  }, [mainRef]);

  return (
    <Flex
      ref={mainRef}
      bg="neutral.background"
      height="100vh"
      justifyContent="space-between"
      style={[{ minHeight: window.innerHeight }]}
      {...rest}
    />
  );
};

export default Main;
