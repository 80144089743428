import React, { useMemo, useState } from 'react';

import { _config } from 'Utilities/envConfig';
import i18n from 'Utilities/i18n';
import { getSessionObject, setSessionObject } from 'Utilities/storage';

const ConfigVersionMessage: React.FC = () => {
  const closeIcon = i18n.t<string>('Global.microcopy.common.iconClose');
  const [disabled, setDisabled] = useState(getSessionObject('ignoreVersion') === true);

  const ignore = () => {
    setSessionObject('ignoreVersion', true);
    setDisabled(true);
  };

  /**
   * This method will create a string that is used for regulatory reason to show clearly the
   * current environment
   */
  const message = useMemo(() => {
    if (disabled) {
      return null;
    }

    if (_config.production) {
      return null;
    }

    let string = `Version (${process.env.VERSION})${
      _config.environment ? `[${_config.environment}]` : ''
    }`;

    if (_config.mock) {
      string += ' - MOCK';
    }

    return string;
  }, [disabled]);

  return message ? (
    <div
      id="__VERSION_DEBUG_INFO"
      className="d-flex align-items-center font-weight-bold line-height-normal min-width-0 h-100"
      style={{ flexShrink: 10000, overflow: 'hidden' }} // Make debug text shrink before anything else
    >
      <button
        id="__VERSION_DEBUG_DISMISS_BUTTON"
        className="btn btn-info btn-sm btn-round mx-1"
        onClick={ignore}
      >
        <img src={closeIcon} alt="" />
      </button>
      <span className="pr-2">{message}</span>
    </div>
  ) : null;
};

export default ConfigVersionMessage;
