import log from 'Utilities/log';
import mediator from 'Utilities/mediator';
import { store } from 'Utilities/store';

import { NAVIGATION } from '../reducers/nav/types';

let lastHandled = new Date();
let initialized = false;

const handlePage = (context: PageContext) => {
  if (context.timing < lastHandled) {
    log.debug('tried to handle a page out of order');
    return;
  }

  store.dispatch({ type: NAVIGATION, context });

  // This block will make sure that the location is set in the store BEFORE
  // any component try to read that
  if (!initialized) {
    mediator.publish('app:ready', { nav: true });
    initialized = true;
  }

  lastHandled = context.timing;
};

mediator.subscribe('page', handlePage);
