import { ActionFromReducer, combineReducers } from '@reduxjs/toolkit';

import { authReducer } from './auth/reducer';
import { configReducer } from './config/reducer';
import { envReducer } from './env/reducer';
import { modalReducer } from './modal/reducer';
import { navReducer } from './nav/reducer';
import { registerReducer } from './register/reducer';
import { scrollReducer } from './scrollPage/reducer';

const reducerMap = {
  config: configReducer,
  nav: navReducer,
  modal: modalReducer,
  env: envReducer,
  register: registerReducer,
  auth: authReducer,
  scrollPage: scrollReducer,
};

export const rootReducer = combineReducers(reducerMap);

export type RootState = ReturnType<typeof rootReducer>;

export type RootActions = ActionFromReducer<typeof rootReducer>;
