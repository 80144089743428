import React, { ReactElement } from 'react';
import { Trans, TransProps } from 'react-i18next';

import { Text } from '@adc-polaris-component-library/component-library';

import { useDefaultTransComponents } from 'Hooks';

import i18n from 'Utilities/i18n';

export interface RichTextProps extends TransProps<''> {
  text: string;
  values?: Record<string, string | number>;
  nativeID?: string;
  onPressLink?: (href: string) => void;
  customNodes?: { strong?: ReactElement; a?: ReactElement };
}

export function RichText({
  text,
  values = {},
  onPressLink,
  customNodes,
  nativeID,
  ...rest
}: RichTextProps) {
  const defaultTransComponents = useDefaultTransComponents(onPressLink, customNodes);

  return (
    <Trans
      i18n={i18n}
      defaults={text}
      values={values}
      parent={({ children }: any) => (
        <Text nativeID={nativeID} color="text.100" fontToken="bodyBaseDefault">
          {children}
        </Text>
      )}
      components={defaultTransComponents}
      {...rest}
    />
  );
}
