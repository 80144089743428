import Mediator from 'mediator-js';

import log, { cleanObjectProperties } from 'Utilities/log';

/**
 * When you want to import this, do so as:
 * import * as mediator from 'Utility/mediator'
 * so you can do mediator.subscribe() or mediator.publish() for example
 *
 * https://github.com/ajacksified/Mediator.js
 */

const logEvents = true;

const _m = new Mediator();

const logEvent = (...args: any[]) => {
  if (!logEvents) {
    return;
  }

  const [id, ...rest] = args;

  if (log.getLogLevel() !== 'DEBUG') {
    log.groupAndTrace(id);
    return;
  }

  const cleanedArgs = rest.map((v) => {
    if (typeof v === 'object') {
      return cleanObjectProperties(v);
    }

    return v;
  });

  log.groupAndTrace(id, ...cleanedArgs);
};

export const subscribe = _m.subscribe.bind(_m);
export const once = _m.once.bind(_m);
export const remove = _m.remove.bind(_m);

export const publish = (...args: any[]): Promise<void> => {
  // want to know who published the event
  logEvent(...args);

  const [id, ...originalArguments] = args;

  return new Promise<void>((resolve, reject) => {
    try {
      _m.publish(id, ...originalArguments);
      resolve();
    } catch (ex) {
      reject(ex);
    }
  });
};

export const publishAndWait = (id: string, ...args: any[]): void => {
  // want to know who published the event
  logEvent(id, ...args);

  _m.publish(id, ...args);
};

export const get = (id: string) => {
  // want to know who published the event
  logEvent(id);

  const data = {} as any;

  _m.publish(id, data);
  return data;
};

export interface MediatorRequest<K = any> {
  [key: string]: any;
  data?: K;
  auth?: { username: string; password: string };
  _topicId?: string;
}

export interface MediatorRequestWithResponse<T = any, K = any> {
  [key: string]: any;
  data?: K;
  _topicId?: string;
  response?: Promise<T>;
}

// you can optionally supply typing automatically for certain mediator requests, here's a commented example
// export function request<T, K>(id: 'api:invoke', request?: MediatorRequest<K>): Promise<LVApiResponse<T>>;
export function request<T, K = any>(
  id: string | MediatorRequest,
  request?: MediatorRequest<K>
): Promise<T>;
export function request<T, K = any>(
  id: string | MediatorRequest,
  request?: MediatorRequest<K>
): Promise<T> {
  if (typeof id === 'string') {
    if (request) {
      request._topicId = id;
    } else {
      request = request || { _topicId: id };
    }
  } else {
    request = id || {};
  }

  if (!request._topicId) {
    throw new Error('missing request._topicId');
  }

  // want to know who published the event
  logEvent(request._topicId, request);

  const _request: MediatorRequestWithResponse<T, K> = request;

  return new Promise<T>((resolve, reject) => {
    try {
      _m.publish(_request._topicId as string, _request);

      if (!_request.response) {
        reject(new Error('missing Response'));
        return;
      }

      _request.response
        .then((val) => {
          resolve(val);
        })
        .catch((ex: any) => {
          reject(ex);
        });
    } catch (ex) {
      reject(ex);
    }
  });
}

export default {
  get,
  once,
  publish,
  publishAndWait,
  remove,
  request,
  subscribe,
};
