export const SCROLL_STATUS = 'SCROLL_STATUS';
export const SCROLL_ACTIVE = 'SCROLL_ACTIVE';
export const SCROLL_RESET = 'SCROLL_RESET';
export const FOOTER_SIZE = 'FOOTER_SIZE';
export const MAIN_SIZE = 'MAIN_SIZE';

export type ScrollState = {
  bottomedOut: boolean;
  scrollValue: number;
  isActive: boolean;
  footerSize: number;
  mainSize: number;
};

export interface ScrollStatusAction {
  type: typeof SCROLL_STATUS;
  status: {
    bottomedOut: boolean;
    scrollValue: number;
  };
}

export interface ScrollActiveAction {
  type: typeof SCROLL_ACTIVE;
  isActive: boolean;
}

export interface FooterSizeAction {
  type: typeof FOOTER_SIZE;
  footerSize: number;
}

export interface MainSizeAction {
  type: typeof MAIN_SIZE;
  mainSize: number;
}

export interface ScrollResetAction {
  type: typeof SCROLL_RESET;
}

export type ScrollActions =
  | ScrollStatusAction
  | ScrollActiveAction
  | FooterSizeAction
  | MainSizeAction
  | ScrollResetAction;
