export const AUTH_SESSION = 'AUTH_SESSION';
export const LOGOUT = 'LOGOUT';

export type AuthState = AuthSession;

export interface AuthAction {
  type: typeof AUTH_SESSION;
  authSession: AuthState;
}

export interface LogoutAction {
  type: typeof LOGOUT;
}

export type AuthActions = AuthAction | LogoutAction;
