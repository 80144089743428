import { INITIALIZATION_ERROR, NAVIGATION, NavActions, NavState, REDIRECTION } from './types';

const initialState: NavState = {
  location: '',
  previousLocation: '',
  query: {},
  queryString: '',
  redirection: '',
};

export const navReducer = (state = initialState, action: NavActions): NavState => {
  switch (action.type) {
    case NAVIGATION:
      return {
        ...state,
        previousLocation: state.location,
        location: action.context.canonicalPath.split('?')[0].replace(/\/$/, '').toLowerCase(),
        query: action.context.query || state.query,
      };
    case REDIRECTION:
      return {
        ...state,
        redirection: action.redirect,
      };
    case INITIALIZATION_ERROR:
      return {
        ...state,
        initErrorStatus: action.initErrorStatus,
        queryString: action.queryString || '',
      };
    default:
      return state;
  }
};
