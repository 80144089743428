import { ApiStatus } from 'Enums';

import { MockApiHandler, MockApiResponse } from './api';

const handleConsent = (): MockApiResponse => {
  return {
    status: ApiStatus.OK,
  };
};

export const consentHandlers: MockApiHandler[] = [
  {
    match: /consent$/,
    handle: handleConsent,
    method: 'POST',
    name: 'consent',
  },
];
