import {
  FOOTER_SIZE,
  MAIN_SIZE,
  SCROLL_ACTIVE,
  SCROLL_RESET,
  SCROLL_STATUS,
  ScrollActions,
  ScrollState,
} from './types';

const initialState: ScrollState = {
  bottomedOut: false,
  scrollValue: 0,
  isActive: false,
  footerSize: 0,
  mainSize: 0,
};

export const scrollReducer = (state = initialState, action: ScrollActions): ScrollState => {
  switch (action.type) {
    case SCROLL_STATUS:
      return {
        ...state,
        ...action.status,
      };
    case SCROLL_ACTIVE:
      return {
        ...state,
        isActive: action.isActive,
      };
    case FOOTER_SIZE:
      return {
        ...state,
        footerSize: action.footerSize,
      };
    case MAIN_SIZE:
      return {
        ...state,
        mainSize: action.mainSize,
      };
    case SCROLL_RESET:
      window.scrollTo(0, 1);

      return {
        ...initialState,
      };

    default:
      return state;
  }
};
